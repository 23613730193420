import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../Loading";
import SingleResult from "../stationpage/SingleResult";
import ProductsGrid from "../stationpage/ProductsGrid";
import MainChart from "../stationpage/MainChart";
import '../../styles/stationPage.css';

export default function GeneralData() {
    const [ data, setData ] = useState(null);
    useEffect(() => {
        async function getData() {
            try {
                await axios({
                    method: 'get',
                    url: 'https://ax-app-746b7eed1e15.herokuapp.com/api/general-data-lubin',
                    // url: 'http://localhost:4000/api/general-data-lubin'
                  }).then(function(response) {
                    setData(response.data);
                  });
            } catch(error) {
                console.log(error)
            }
        };
        getData();
    }, [] );
    return !data ? <Loading/> : (
        <div className='station-page'>
          <div className="upper-data">
              <ProductsGrid data={data.today.fuels} previousData={data.previous.fuels}/>
          </div>
          <div className='data'>
            <div className='left-column'>
              <SingleResult today={data.today.fuelsVolume} previous={data.previous.fuelsVolume} props={{name: 'liters', title: 'Litraż sumarycznie', item: 'l'}}/>
              <SingleResult today={data.today.nettoSale} previous={data.previous.nettoSale} props={{name: 'cash', title: 'Sprzedaż paliw netto', item: 'zł'}}/>
              <SingleResult today={data.today.profit} previous={data.previous.profit} props={{name: 'profit', title: 'Marża operacyjna', item: 'zł'}}/> 
            </div>
            <div className="chart">
              <MainChart days={data.today.days} fuels={data.today.fuels} prevFuels={data.previous.fuels}/>
            </div>
          </div>
        </div>
    )
}