import { useState, useEffect } from "react";
import axios from 'axios';
import Loading from '../Loading';
import '../../styles/shopDashboard.css';
import ShopData from "./ShopData";

export default function ShopDashboard() {
    const date = new Date();
    const yesterday = new Date(date);
    yesterday.setHours(0,0,0);
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ request, setRequest ] = useState({
        start: yesterday,
        stop: date,
        docs: ['Paragony', 'DokFV', 'DokWZ']
    });
    function formatDate(dateX) {
        const date = new Date(dateX)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hour}:${minutes}`;
    };

    useEffect( () => {
        async function getData() {
            setLoading(true)
            try {
                await axios({
                    method: 'post',
                    url: 'https://ax-app-746b7eed1e15.herokuapp.com/api/shop-dashboard',
                    // url: 'http://localhost:4000/api/shop-dashboard',
                    data: request
                }).then(function(response) {
                    setData(response);
                });
            } catch(error) { 
                setData({
                    data: {
                        numbers: {
                            saleVolume: 0,
                            saleIncome: 0,
                            saleProfit: 0,
                            saleBrutto: 0,
                            product: {
                                name: [[{NAZWA: 'Error'}]],
                                qty: 0
                            }
                        },
                        preNumbers: {
                            saleVolume: 0,
                            saleIncome: 0,
                            saleProfit: 0,
                            saleBrutto: 0,
                            product: {
                                name: [[{NAZWA: 'Error'}]],
                                qty: 0
                            }
                        },
                        chart: [
                            [{name: ['error'], qty: 0, brutto: 0}],
                            [{name: ['error'], qty: 0, brutto: 0}]
                        ]
                    }
                })
                setLoading(false)
                console.log(error)
            }
            setLoading(false)
        };
        
        getData();
    }, [request]);

    return (
        <div className="shopDashboard">
            <p>Dane sprzedaży <b>sklepu</b></p>
                    <div className="labels">
                        <label>
                            <p>Od:</p>
                            <input type="datetime-local" defaultValue={formatDate(request.start)} onChange={e => setRequest(prev => ({...prev, start: e.target.value}))}/>
                        </label>
                        <label>
                            <p>Do:</p>
                            <input type="datetime-local" defaultValue={formatDate(request.stop)} onChange={e => setRequest(prev => ({...prev, stop: e.target.value}))}/>
                        </label>
                    </div>
            {loading ? <Loading/> : <ShopData data={data.data} query={request}/>}
        </div>
    )
    
}