import toPLN from "../global/toPLN"

export default function SingleResult({today, previous, props}) {
    return (
        <div className="single-result">
            <p>{props.title}</p>
            <h2>{props.item === 'zł' ? toPLN(today) : today.toFixed(2) + ' ' + props.item}</h2>
            <h6><span style={previous > today ? {color: 'red'} : {color: 'green'}}>{(((today * 100) / previous) - 100).toFixed(2)}%</span> niż w poprzednim tygodniu</h6>
        </div>
    )
}