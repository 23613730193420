import { useContext, useState } from "react";
import { Navigate, Outlet, useLocation, Link } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import logo from '../assets/ax_white.png';
import '../styles/privateRoute.css';
 
export default function PrivateRoute() {
 const { user } = useContext(UserContext);
 const location = useLocation();
 const redirectLoginUrl = `/login?redirectTo=${encodeURI(location.pathname)}`;
 const { logOutUser } = useContext(UserContext);
 const logOut = async () => {
   try {
     const loggedOut = await logOutUser();
     if (loggedOut) {
       window.location.reload(true);
     }
   } catch (error) {
     alert(error)
   }
 }
 const [ menu, setMenu ] = useState(false);
 const Menu = () => {
  // https://www.svgrepo.com/collection/carbon-design-pictograms/11
  return (
    <ul>
      <li><Link to="/" onClick={ () => setMenu(false) }>
        {/* <span><svg viewBox="0 0 32 32"><path d="M31,31.36H1v-0.72h1.64V19h0.72v11.64h9.28V19c0-0.199,0.161-0.36,0.36-0.36h6  c0.199,0,0.36,0.161,0.36,0.36v11.64h9.279V19h0.721v11.64H31V31.36z M13.36,30.64h5.28V19.36h-5.28  C13.36,19.36,13.36,30.64,13.36,30.64z M13,16.363v-0.72l6-0.003v0.72L13,16.363z M30.745,16.254L16,1.509L1.254,16.254  l-0.509-0.509l15-15c0.141-0.141,0.368-0.141,0.509,0L28.64,13.131V9h0.721v4.851l1.895,1.895L30.745,16.254z"/></svg>
        </span> */}
        Świdnica
      </Link></li>
      <li><Link to="/station-lubin" onClick={ () => setMenu(false) }>
        <span>
          {/* <svg viewBox="0 0 32 32"><path d="M31,31.36H1v-0.72h1.64V19h0.72v11.64h9.28V19c0-0.199,0.161-0.36,0.36-0.36h6  c0.199,0,0.36,0.161,0.36,0.36v11.64h9.279V19h0.721v11.64H31V31.36z M13.36,30.64h5.28V19.36h-5.28  C13.36,19.36,13.36,30.64,13.36,30.64z M13,16.363v-0.72l6-0.003v0.72L13,16.363z M30.745,16.254L16,1.509L1.254,16.254  l-0.509-0.509l15-15c0.141-0.141,0.368-0.141,0.509,0L28.64,13.131V9h0.721v4.851l1.895,1.895L30.745,16.254z"/></svg> */}
        </span>
        Lubin
      </Link></li>
      <li><Link to="/raports" onClick={ () => setMenu(false) }>
        <span><svg viewBox="0 0 32 32"><path d="M6,10.5C6,10.776,5.776,11,5.5,11S5,10.776,5,10.5S5.224,10,5.5,10S6,10.224,6,10.5z M7.5,8  C7.224,8,7,8.224,7,8.5S7.224,9,7.5,9S8,8.776,8,8.5S7.776,8,7.5,8z M10.5,8C10.224,8,10,8.224,10,8.5S10.224,9,10.5,9  S11,8.776,11,8.5S10.776,8,10.5,8z M6.5,6C6.224,6,6,6.224,6,6.5S6.224,7,6.5,7S7,6.776,7,6.5S6.776,6,6.5,6z M8.5,5  C8.224,5,8,5.224,8,5.5S8.224,6,8.5,6S9,5.776,9,5.5S8.776,5,8.5,5z M11.5,5C11.224,5,11,5.224,11,5.5S11.224,6,11.5,6  S12,5.776,12,5.5S11.776,5,11.5,5z M30.36,24c0-3.507-2.854-6.36-6.36-6.36s-6.36,2.854-6.36,6.36s2.854,6.36,6.36,6.36  S30.36,27.507,30.36,24z M23.64,24c0,0.199,0.161,0.36,0.36,0.36h5.629c-0.187,2.942-2.64,5.279-5.629,5.279  c-3.11,0-5.64-2.529-5.64-5.64c0-2.989,2.337-5.442,5.279-5.629L23.64,24L23.64,24z M24.36,18.371  c2.823,0.179,5.09,2.445,5.269,5.269H24.36V18.371z M13.36,30v-6c0-0.199-0.161-0.36-0.36-0.36h-2c-0.199,0-0.36,0.161-0.36,0.36v6  c0,0.199,0.161,0.36,0.36,0.36h2C13.199,30.36,13.36,30.199,13.36,30z M11.36,24.36h1.28v5.279h-1.28V24.36z M9.36,30V20  c0-0.199-0.161-0.36-0.36-0.36H7c-0.199,0-0.36,0.161-0.36,0.36v10c0,0.199,0.161,0.36,0.36,0.36h2C9.199,30.36,9.36,30.199,9.36,30  z M7.36,20.36h1.28v9.279H7.36V20.36z M5.36,30v-4c0-0.199-0.161-0.36-0.36-0.36H3c-0.199,0-0.36,0.161-0.36,0.36v4  c0,0.199,0.161,0.36,0.36,0.36h2C5.199,30.36,5.36,30.199,5.36,30z M3.36,26.36h1.28v3.279H3.36V26.36z M30,13.64H18.36v-2.28H22  c0.096,0,0.188-0.038,0.255-0.105L27.15,6.36H30V5.64h-3c-0.096,0-0.188,0.038-0.255,0.105L21.85,10.64h-3.49V2h-0.72v12  c0,0.199,0.161,0.36,0.36,0.36h12V13.64z M14,13.64H2.36V2H1.64v12c0,0.199,0.161,0.36,0.36,0.36h12V13.64z"/></svg></span>
        Raporty
      </Link></li>
      <li><Link to="/prices" onClick={ () => setMenu(false) }>
        <span><svg viewBox="0 0 32 32"><path d="M31,31.36H1c-0.199,0-0.36-0.161-0.36-0.36V1h0.72v29.64h2.28V25c0-0.199,0.161-0.36,0.36-0.36h6   c0.199,0,0.36,0.161,0.36,0.36v5.64h2.28V15c0-0.199,0.161-0.36,0.36-0.36h6c0.199,0,0.36,0.161,0.36,0.36v15.64h2.279V3   c0-0.199,0.161-0.36,0.36-0.36h6c0.199,0,0.36,0.161,0.36,0.36v27.64H31V31.36z M22.36,30.64h5.279V3.36H22.36V30.64z M13.36,30.64   h5.28V15.36h-5.28C13.36,15.36,13.36,30.64,13.36,30.64z M4.36,30.64h5.28v-5.28H4.36V30.64z"/></svg></span>
        Ceny
      </Link></li>
      <li><Link to="/fuel-storage" onClick={ () => setMenu(false) }>
        <span><svg viewBox="0 0 32 32" strokeWidth="1"><path d="M16,31.36C7.53,31.36,0.64,24.47,0.64,16S7.53,0.64,16,0.64S31.36,7.53,31.36,16  S24.47,31.36,16,31.36z M16,1.36C7.927,1.36,1.36,7.927,1.36,16c0,8.072,6.567,14.64,14.64,14.64c0.673,0,1.336-0.045,1.985-0.134  l12.521-12.521c0.089-0.649,0.134-1.312,0.134-1.985c0-0.677-0.046-1.343-0.135-1.996l-14.25,14.25l-0.509-0.51l14.613-14.613  c-0.21-1.053-0.533-2.065-0.957-3.025L16.254,23.255l-0.509-0.51L29.074,9.417c-0.437-0.863-0.956-1.678-1.548-2.434L16.254,18.255  l-0.509-0.51l11.321-11.32c-0.616-0.71-1.3-1.362-2.039-1.943l-8.773,8.772l-0.509-0.509l8.699-8.699  C23.652,3.484,22.801,3,21.905,2.603l-5.651,5.65l-0.509-0.509l5.438-5.438c-1.009-0.383-2.071-0.658-3.172-0.81l-1.757,1.757  l-0.509-0.509L17.091,1.4C16.73,1.374,16.367,1.36,16,1.36z M30.281,19.228L19.228,30.281  C24.714,29.042,29.042,24.714,30.281,19.228z"/></svg></span>
        Magazyn
      </Link></li>
      <li><Link to="/shop-raport" onClick={ () => setMenu(false) }>
        <span><svg viewBox="0 0 32 32"><path d="M26,31.36c-1.302,0-2.36-1.059-2.36-2.36s1.059-2.36,2.36-2.36s2.36,1.059,2.36,2.36
	S27.302,31.36,26,31.36z M26,27.36c-0.904,0-1.64,0.735-1.64,1.64s0.735,1.64,1.64,1.64s1.64-0.735,1.64-1.64S26.904,27.36,26,27.36
	z M12.5,31.36c-1.301,0-2.36-1.059-2.36-2.36s1.059-2.36,2.36-2.36s2.36,1.059,2.36,2.36S13.801,31.36,12.5,31.36z M12.5,27.36
	c-0.904,0-1.64,0.735-1.64,1.64s0.736,1.64,1.64,1.64s1.64-0.735,1.64-1.64S13.404,27.36,12.5,27.36z M29,25.36H12.319
	c-2.049,0-3.132-1.109-3.739-3.827L3.717,1.36H1V0.64h3c0.166,0,0.311,0.113,0.35,0.275l1.707,7.022L6.07,7.934l2.002,8.296
	c0.59,2.325,1.549,3.409,3.018,3.409h15.499c1.361,0,2.155-0.789,2.425-2.412l1.57-10.867H9V5.64h22
	c0.104,0,0.204,0.045,0.272,0.124c0.068,0.079,0.099,0.184,0.084,0.287l-1.632,11.288c-0.416,2.498-1.936,3.021-3.137,3.021H11.089
	c-0.908,0-1.666-0.32-2.28-0.971l0.474,1.981c0.539,2.415,1.333,3.269,3.037,3.269H29V25.36z"/></svg></span>
        Sklep
      </Link></li>
      <li><Link to="/fuel-deliveries" onClick={ () => setMenu(false) }>
        <span><svg viewBox="0 0 32 32"><path d="M26,28.36c-1.731,0-3.161-1.316-3.341-3H10.341c-0.18,1.684-1.61,3-3.341,3s-3.161-1.316-3.341-3H3  c-1.301,0-2.36-1.059-2.36-2.36v-7c0-0.068,0.019-0.131,0.051-0.185L4.73,8.82c0.064-0.111,0.183-0.18,0.312-0.18h7.599V4  c0-0.199,0.161-0.36,0.36-0.36h18c0.199,0,0.36,0.161,0.36,0.36v21c0,0.199-0.161,0.36-0.36,0.36h-1.659  C29.161,27.044,27.731,28.36,26,28.36z M26,22.36c-1.456,0-2.64,1.184-2.64,2.64s1.184,2.64,2.64,2.64s2.64-1.184,2.64-2.64  S27.456,22.36,26,22.36z M7,22.36c-1.456,0-2.64,1.184-2.64,2.64S5.544,27.64,7,27.64S9.64,26.456,9.64,25S8.456,22.36,7,22.36z   M29.341,24.64h1.299V4.36H13.36v20.28h9.299c0.18-1.684,1.609-3,3.341-3S29.161,22.956,29.341,24.64z M10.341,24.64h2.299v-8.28  H1.36V23c0,0.904,0.736,1.64,1.64,1.64h0.659c0.18-1.684,1.61-3,3.341-3S10.161,22.956,10.341,24.64z M10.36,15.64h2.28V9.36h-2.28  V15.64z M1.624,15.64H9.64V9.36H5.25L1.624,15.64z M11,18.36H9v-0.72h2V18.36z M29,16.36h-8v-0.72h8V16.36z M29,13.36H19v-0.72h10  V13.36z M29,10.36H17V9.64h12V10.36z M29,7.36H15V6.64h14V7.36z"/></svg></span>
        Dostawy paliw
      </Link></li>
      <li><Link to="/app-safety" onClick={ () => setMenu(false) }>
        <span><svg viewBox="0 0 32 32"><path d="M28,31.36c-1.731,0-3.161-1.316-3.341-3H16c-0.199,0-0.36-0.161-0.36-0.36V16.36H7.341  c-0.18,1.684-1.61,3-3.341,3c-1.853,0-3.36-1.508-3.36-3.36S2.147,12.64,4,12.64c1.731,0,3.161,1.316,3.341,3h8.299V4  c0-0.199,0.161-0.36,0.36-0.36h8.659c0.18-1.684,1.609-3,3.341-3c1.853,0,3.36,1.507,3.36,3.36S29.853,7.36,28,7.36  c-1.731,0-3.161-1.316-3.341-3H16.36v11.28h8.299c0.18-1.684,1.609-3,3.341-3c1.853,0,3.36,1.507,3.36,3.36s-1.508,3.36-3.36,3.36  c-1.731,0-3.161-1.316-3.341-3H16.36v11.28h8.299c0.18-1.684,1.609-3,3.341-3c1.853,0,3.36,1.508,3.36,3.36S29.853,31.36,28,31.36z   M28,25.36c-1.456,0-2.64,1.184-2.64,2.64s1.184,2.64,2.64,2.64s2.64-1.184,2.64-2.64S29.456,25.36,28,25.36z M28,13.36  c-1.456,0-2.64,1.185-2.64,2.64c0,1.456,1.184,2.64,2.64,2.64s2.64-1.184,2.64-2.64S29.456,13.36,28,13.36z M4,13.36  c-1.456,0-2.64,1.185-2.64,2.64c0,1.456,1.185,2.64,2.64,2.64S6.64,17.456,6.64,16S5.456,13.36,4,13.36z M28,1.36  c-1.456,0-2.64,1.185-2.64,2.64S26.544,6.64,28,6.64S30.64,5.456,30.64,4S29.456,1.36,28,1.36z"/></svg></span>
        Performance
      </Link></li>
    </ul>
  )
 };
 return (
    <div className="privateBody" style={ menu ? {overflow: 'hidden'} : {overflow: 'visible'} }>
        <header>
            <Link to="/">
              <img src={logo} alt="logo"/>
            </Link>
            <div className="desktopMenu">
              <Menu/>
            </div>
            <button onClick={logOut}>Wyloguj się<span><svg width="12px" height="12px" viewBox="0 0 24 24" fill="none"><path d="M21 12L13 12" stroke="#c9c9c9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M18 15L20.913 12.087V12.087C20.961 12.039 20.961 11.961 20.913 11.913V11.913L18 9" stroke="#c9c9c9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/><path d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19" stroke="#c9c9c9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></span></button>
            <div className="mobileBttn" onClick={ () => setMenu(!menu) }>
              <span style={menu ? {rotate: '45deg', marginTop: '2vh'} : {rotate: '0deg'}}></span>
              <span style={menu ? {display: 'none'} : {display: 'block'}}></span>
              <span style={menu ? {rotate: '-45deg', marginBottom: '3vh'} : {rotate: '0deg'}}></span>
            </div>
            <div className="mobileMenu" style={menu ? {left: '0vw'} : {left: '100vw'}}>
              <Menu/>
              <button onClick={logOut}>Wyloguj się</button>
            </div>
        </header>
        <div className="content">
            { !user ? <Navigate to={redirectLoginUrl} /> : <Outlet /> }
        </div>
    </div>
 ) 
}