import { useState, useEffect } from 'react';
import axios from 'axios';
import Select from './Select';
import InputDate from './InputDate';
import { fuels } from '../global/fuels';
import { documents } from '../global/documents';
import ProductsResults from './ProductsResults';
import Loading from '../Loading';
import '../../styles/previousday.css';

export default function PreviousDay({station}) {
    function startingDate(sdate, num) {
        const date = new Date(sdate);
        date.setDate(date.getDate() - num);
        date.setUTCHours(23,0,0,0);
        return date;
    };
    
    function stoppingDate(sdate, num) {
        const date = new Date(sdate);
        date.setDate(date.getDate() - num);
        date.setUTCHours(23,0,0,0);
        return date;
    };

    const date = new Date();
    const [ data, setData ] = useState();
    const [ product, setProduct ] = useState('');
    const [ document, setDocument ] = useState('All');
    const [ start, setStart ] = useState(startingDate(date, 2));
    const [ end, setEnd ] = useState(stoppingDate(date, 1));
    
    useEffect( () => {
    async function insertData() {
        try {
            setData();
            await axios({
                method: 'post',
                url: `https://ax-app-746b7eed1e15.herokuapp.com/api/day-raport-by-station`,
                // url: `http://localhost:4000/api/day-raport-by-station`,
                data: { station, product, start, end, document }
        }).then(function(response) {
            setData(response.data)          
        })
        } catch(error) { console.log(error) }
    };
    insertData();
    }, [ product, start, end, document ] );

    return (
        <div className='previous-day-results'>
            <form className='inputs'>
                <h1>Dane za poprzedni poprzedni dzień</h1>
                <div className='input-box'>
                    <Select label="Produkt" setData={setProduct} options={fuels}/>
                    <Select label="Dokument" setData={setDocument} options={documents}/>
                    <InputDate label="od:" type="start" date={start} setData={setStart} dateFunction={startingDate}/>
                    <InputDate label="do:" type="stop" date={end} setData={setEnd} dateFunction={stoppingDate}/>
                </div>
            </form>
            {!data ? <Loading/> : <ProductsResults data={data.cumulative}/>}
            {/* {!data ? <Loading/> : <PreviousTable data={data}/>} */}
        </div>
    )
}