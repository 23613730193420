export const fuels = [
    {
        code: '',
        name: 'Wszystkie',
        shortName: 'Wszystkie',
        order: 0,
        color: '#000'
    },
    {
        code: 4243,
        name: 'Olej napędowy',
        shortName: 'ON',
        order: 1,
        color: '#00000091'
    },
    {
        code: 4241,
        name: 'Benzyna 95',
        shortName: 'PB95',
        order: 2,
        color: '#58b06f'
    },
    {
        code: 4244,
        name: 'Autogaz LPG',
        shortName: 'LPG',
        order: 3,
        color: '#e85656'
    },
    {
        code: 4242,
        name: 'Benzyna 98',
        shortName: 'PB98',
        order: 4,
        color: '#5a8dbf'
    },
    {
        code: 9521,
        name: 'Olej napędowy Premium',
        shortName: 'ON+',
        order: 5,
        color: '#133252'
    },
    {
        code: 4245,
        name: 'AdBlue',
        shortName: 'AdBlue',
        order: 6,
        color: '#4287f5'
    },
]