import formatNumber from "../global/formatNumber";

export default function Tanks({data}) {
    const Capacity = props => {
        const { level, rest, full } = props;
        const percentage = level / (full / 100);
        return (
            <>
            <div className="capacity">
                <div className="level" style={{height: percentage}}/>
            </div>
            <div className="results">
                <p>Stan: <span>{formatNumber(level)}l - ({percentage.toFixed(2)}%)</span></p>
                <p>Wolne: <span>{formatNumber(rest)}l</span></p>
                <p>Max: <span>{formatNumber(full)}l</span></p>
            </div>
            </>
        )
    }
    return data && data.map((item, index) => 
        <div key={index} className="tank">
            <h2 className="name">{item.fuel}</h2>
            <Capacity level={item.level} rest={item.rest} full={item.full}/>
        </div>
    )
}