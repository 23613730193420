export default function InputDate({label, type, date, setData, dateFunction}) {
    return (
        <label>
            <p>{label}</p>
            <input
                type="date"
                onChange={e => {setData(dateFunction(e.target.value, type === 'start' ? 1 : 0))}}
                defaultValue={`${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()}`}
            />
        </label>
    )
};