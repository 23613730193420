export default function ProductsGrid({data, previousData}) {

    const gridcolors = [
        { code: 4243, color: '#dedede'},
        { code: 4241, color: '#d1d1d1'},
        { code: 4244, color: '#cccccc'},
        { code: 4242, color: '#bfbfbf'},
        { code: 9521, color: '#cccccc'},
    ];

    const SingleProduct = ({item, index}) => {
        return (
            <div key={index} className="single-product" style={{backgroundColor: gridcolors.find(el => el.code === item.code).color}}>
                <h6>{item.name}</h6>
                <h3>{item.qty.toFixed(2)} <span>l</span></h3>
                <p><span>Śr. marża</span> {(item.profit / item.qty).toFixed(2)} zł</p>
                <p style={((item.qty * 100) / previousData.filter(el => el.code === item.code).map(rec => rec.qty)) - 100 < 0 ? {color: 'red'} : {color: 'green'}}><span>Tydzień temu</span> {(((item.qty * 100) / previousData.filter(el => el.code === item.code).map(rec => rec.qty)) - 100).toFixed(2)}%</p>
            </div>
        )
    };
    return data && previousData && data.map((item, index) => <SingleProduct key={index} item={item} index={index}/>)
}