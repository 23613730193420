import { useState, useEffect } from "react";
import axios from 'axios';
import Tanks from "./Tanks";
import Loading from "../Loading";

export default function GetStorage() {
    const [ data, setData ] = useState();

    async function getData(e) {
        try {
            const station = e.target.value;
            const response = await axios.get(`https://ax-app-746b7eed1e15.herokuapp.com/api/fuel-storage?station=${station}`);
            setData(response.data);   
        } catch(error) { console.log(error) }
    };

    return (
        <div className="fuelStorage">
            <div className="fuel-storage-query">
                <h1>Stany zbiorników</h1>
                <select defaultValue="" onChange={getData}>
                    <option value="" disabled>-- Wybierz stację --</option>
                    <option value="lubin">Lubin, Ścinawska</option>
                    <option value="swidnica" disabled>Świdnica, Towarowa</option>
                </select>
            </div>
            {!data ? <Loading/> : <div className="tankWrap"><Tanks data={data}/></div>}
        </div>
    )
}