export const documents = [
    {
        code: 'All',
        name: 'Wszystkie'
    },
    {
        code: 'PADocs',
        name: 'Paragony'
    },
    {
        code: 'FVDocs',
        name: 'Faktury VAT'
    },
    {
        code: 'WZDocs',
        name: 'Dokumenty WZ'
    },
];