import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../Loading";

export default function LastSale() {
    const [ data, setData ] = useState([]);
    useEffect( () => {
        async function getData() {
            try {
                axios({
                    method: 'get',
                    url: 'https://ax-app-746b7eed1e15.herokuapp.com/api/safety-last-sale'
                    // url: 'http://localhost:4000/api/safety-last-sale'
                }).then(function(response) {
                    setData(response)
                });
            } catch(error) { console.log(error) }
        };
        getData();
    }, []);

    if(data.length !== 0) {
        const RenderStatus = () => {
            return data && data.data.map(item =>
                <tr>
                    <td><p>{item[0].type}</p></td>
                    <td><p>{`${new Date(item[0].date).getHours()}:${new Date(item[0].date).getMinutes() < 10 ? '0' + new Date(item[0].date).getMinutes() : new Date(item[0].date).getMinutes()}`}</p></td>
                    <td><p>{`${new Date(item[0].date).getDate()}.${(new Date(item[0].date).getMonth() + 1) < 10 ? '0' + (new Date(item[0].date).getMonth() + 1) : new Date(item[0].date).getMonth() + 1}`}</p></td>
                    <td><p>{item[0].item}</p></td>
                    <td><p>{item[0].printer || '-'}</p></td>
                    <td><p>{item[0]._id || '-'}</p></td>
                    <td><p>{item[0].tankterminal}</p></td>
                </tr>
            )
        }
        return (
            <div className="lastSale">
                <p style={{borderBottom: '1px solid #cdcdcd', marginBottom: '2vh', paddingBottom: '1vh'}}>App last update</p>
                { data.length === 0 ? <Loading/> : 
                <div className="tableWrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Hour</th>
                            <th>Day</th>
                            <th>Product</th>
                            <th>Printer</th>
                            <th>ID</th>
                            <th>TankTerminal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <RenderStatus/>
                    </tbody>
                </table>
                </div> }
                <p style={{padding: '2vh 0', textAlign: 'center'}}>V 3.5.3</p>
            </div>
        )
    } else {
        return (
            <div className="lastSale">
                <p>App status <span>PROBLEM</span></p>
            </div>
        )
    }
}