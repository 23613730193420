import GeneralData from "../components/station lubin/GeneralData";
import PreviousDay from "../components/stationpage/PreviousDay";

export default function StationLubin() {
    return (
        <div>
            <h1>Stacja paliw: Lubin</h1>
            <GeneralData/>
            <PreviousDay station='lubin'/>
        </div>
    )
}