import { Chart as ChartJS, CategoryScale, LinearScale, Filler, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Loading from '../Loading';
ChartJS.register( CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Filler, Title, Tooltip, Legend );

export default function MainChart(props) {    
      const chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: { legend: { position: 'top', }, title: { display: false } },
      };
      const chartData = {
          labels: props.days.length !== 0 && props.days.map(item => item.label),
          datasets: [
            {
              label: 'Paliwa',
              data: props.days.length !== 0 && props.days.map(item => item.qty),
              backgroundColor: '#a3a3a3',
              fill: false,
              borderColor: '#a3a3a3',
              tension: 0, 
            },
            {
              label: 'ON',
              data: props.days.length !== 0 && props.days.map(item => item.onQty),
              backgroundColor: '#000000EA',
              fill: false,
              borderColor: '#00000091',
              tension: 0, 
            },
            {
              label: 'PB95',
              data: props.days.length !== 0 && props.days.map(item => item.pb95Qty),
              backgroundColor: '#58b06f',
              fill: false,
              borderColor: '#58b06f',
              tension: 0, 
            },
            {
              label: 'LPG',
              data: props.days.length !== 0 && props.days.map(item => item.lpgQty),
              backgroundColor: '#e85656',
              fill: false,
              borderColor: '#e85656',
              tension: 0, 
            },
            {
              label: 'PB98',
              data: props.days.length !== 0 && props.days.map(item => item.pb98Qty),
              backgroundColor: '#5a8dbf',
              fill: false,
              borderColor: '#5a8dbf',
              tension: 0, 
            },
            {
              label: 'ON+',
              data: props.days.length !== 0 && props.days.map(item => item.onplusQty),
              backgroundColor: '#133252',
              fill: false,
              borderColor: '#133252',
              tension: 0, 
            }
          ]
      };
    return !props ? <Loading/> : <Line options={chartOptions} data={chartData}/>
    
}