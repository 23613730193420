import '../../styles/yestInfo.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import YestTable from './YestTable';
import Loading from '../Loading';
import Cumulative from './Cumulative';

export default function YestInfo() {
  function startingDate(sdate, num) {
    const date = new Date(sdate);
    date.setDate(date.getDate() - num);
    date.setUTCHours(23,0,0,0);
    return date;
  };

  function stoppingDate(sdate, num) {
    const date = new Date(sdate);
    date.setDate(date.getDate() - num);
    date.setUTCHours(23,0,0,0);
    return date;
  };

  const date = new Date();
  const [ data, setData ] = useState();
  const [ product, setProduct ] = useState('');
  const [ document, setDocument ] = useState('All');
  const [ start, setStart ] = useState(startingDate(date, 2));
  const [ end, setEnd ] = useState(stoppingDate(date, 1));

  useEffect( () => {
    async function insertData() {
      try {
        setData();
        axios({
          method: 'post',
          url: "https://ax-app-746b7eed1e15.herokuapp.com/api/day-raport",
          // url: 'http://localhost:4000/api/day-raport',
          data: { product, start, end, document }
        }).then(function(response) {
          setData(response)          
        })
      } catch(error) { console.log('error insert') }
    };
    insertData();
  }, [ product, start, end, document ] );

  return (
    <>
        <div className='title'>
            <h1>Raport za poprzedni dzień</h1>
              <div className='dates'>
                <label>
                  <p>Produkt:</p>
                  <select defaultValue="" onChange={ e => {setProduct(e.target.value)} }>
                    <option value="">Wszystko</option>
                    <option value={4243}>Olej napędowy</option>
                    <option value={4241}>Benzyna 95</option>
                    <option value={4244}>LPG</option>
                    <option value={4245}>AdBlue</option>
                  </select>
                </label>
                <label>
                  <p>Dokument:</p>
                  <select defaultValue="All" onChange={ e => {setDocument(e.target.value)} }>
                    <option value="All">Wszystkie</option>
                    <option value="Paragony">Paragony</option>
                    <option value="DokFV">FV</option>
                    <option value="DokWZ">WZ</option>
                  </select>
                </label>        
                <label>
                  <p>Od:</p>
                  <input onChange={ e => {setStart(startingDate(e.target.value, 1))} } type="date" defaultValue={`${start.getFullYear()}-${(start.getMonth() + 1) < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1}-${start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()}`}/>
                </label>        
                <label>
                  <p>Do:</p>
                  <input onChange={ e => {setEnd(stoppingDate(e.target.value, 0))} } type="date" defaultValue={`${start.getFullYear()}-${(start.getMonth() + 1) < 10 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1}-${start.getDate() < 10 ? ('0' + start.getDate()) : start.getDate()}`}/>
                </label>
              </div>
        </div>
        <div className='cumulatives'>
          {!data ? <Loading/> : <Cumulative data={data.data} product={product}/>}
        </div>
        <div className='yestTable'>
          {!data ? <Loading/> : <YestTable data={data}/>}
        </div>
    </>
  )
}