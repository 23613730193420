import React from 'react';
import '../../styles/cumulative.css';

export default function Cumulative(props) {
    const data = props.data;
    const screen = window.matchMedia('(max-width: 767px)').matches;
    const products = [
        { a: 1, item: 4243, name: 'ON', color: '#252525' },
        { a: 2, item: 4241, name: 'PB95', color: '#49a665' },
        { a: 3, item: 4244, name: 'LPG', color: '#a64949' },
        { a: 4, item: 4245, name: 'AdBlue', color: '#496da6' }
    ];
    const findProductName = (item) => {
        const product = products.find(product => product.item === item);
        return product ? product.name : 'PPaliw';
    };
    const findProductColor = (item) => {
        const product = products.find(product => product.item === item);
        return product ? product.color : '#252525';
    };
    const sumTotalQty = data.cumulative.reduce((acc, currentValue) => acc + currentValue.totalQty, 0);
    const sumNetto = data.cumulative.reduce((acc, currentValue) => acc + currentValue.netto, 0);
    if (data.pozap.totalQty > 0) {
        return (
            <>
                <div className='fuel'>
                    <h6>Paliwa</h6>
                    <p>Litraż:</p>
                    <h2>{(sumTotalQty).toFixed(2)} l</h2>
                    <p>Sprzedaż netto:</p>
                    <h2>{(sumNetto).toFixed(2)} zł</h2>
                </div>
                { data && data.cumulative.map(record => (
                    <div key={record.items} className='fuel' id={`Div${record.items}`}>
                        <h6 style={{borderLeft: `3px solid ${findProductColor(record.items)}`, paddingLeft: '5px'}}>{findProductName(record.items)}</h6>
                        <p>Litraż:</p>
                        <h2>{record.totalQty.toFixed(2)} l</h2>
                        <p>Sprzedaż netto:</p>
                        <h2>{record.netto.toFixed(2)} zł</h2>
                    </div>
                ))}
                <div className='fuel' id="DivP">
                    <h6 style={{borderLeft: '3px solid lightgray', paddingLeft: '5px'}}>Pozapaliwowe:</h6>
                    <p>Ilość:</p>
                    <h2>{(data.pozap.totalQty).toFixed(2)} szt.</h2>
                    <p>Sprzedaż netto:</p>
                    <h2>{(data.pozap.netto).toFixed(2)} zł</h2>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div style={!screen ? {width: '15%'} : {width: '40%'}} className='fuel allFuels'>
                    <h6>Paliwa</h6>
                    <p>Litraż:</p>
                    <h2>{(sumTotalQty).toFixed(2)} l</h2>
                    <p>Sprzedaż netto:</p>
                    <h2>{(sumNetto).toFixed(2)} zł</h2>
                </div>
                {data && data.cumulative.map(record => (
                    <div style={!screen ? {width: '15%'} : {width: '40%'}} key={record.items} className='fuel' id={`Div${record.items}`}>
                        <h6 style={{borderLeft: `3px solid ${findProductColor(record.items)}`, paddingLeft: '5px'}}>{findProductName(record.items)}</h6>
                        <p>Litraż:</p>
                        <h2>{record.totalQty.toFixed(2)} l</h2>
                        <p>Sprzedaż netto:</p>
                        <h2>{record.netto.toFixed(2)} zł</h2>
                    </div>
                ))}
            </>
        );
    }
}
