import { fuels } from "../global/fuels";
import toPLN from "../global/toPLN";

export default function ProductsResults({data}) {
    const sortedFuels = data.map(item => {
            const fuel = fuels.find(f => f.code === item.items);
            return fuel ? { ...item, ...fuel } : null;
        }).filter(Boolean).sort((a, b) => a.order - b.order);

    const AllProducts = () => {
        let allQty = 0;
        let allNetto = 0;
        for(const i in data) {
            allQty += data[i].totalQty;
            allNetto += data[i].netto;
        };
        return (
            <div className="products">
                <p>Wszystko</p>
                <h5>Wolumen:</h5>
                <h6>{allQty.toFixed(2)} <span>l</span></h6>
                <h5>Sprzedaż netto:</h5>
                <h6>{toPLN(allNetto)}</h6>
            </div>
        )
    };

    const Product = ({product}) => (
        <div className="products">
            <p style={{borderLeft: `3px solid ${product.color}`, paddingLeft: '3px'}}>{product.shortName}</p>
            <h5>Wolumen:</h5>
            <h6>{product.totalQty.toFixed(2)} <span>l</span></h6>
            <h5>Sprzedaż netto:</h5>
            <h6>{toPLN(product.netto)}</h6>
        </div>
    );
    
    return (
        <div className="previous-day-results-products">
            {data && <AllProducts/>}
            {data && sortedFuels.map((item, index) => <Product key={index} product={item}/>)}
        </div>
    )
}